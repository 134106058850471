<template>
    <div v-if="dataLoaded">
        <v-card width="500">
            <v-card-title><h2>{{ getType(ctzId) }}</h2></v-card-title>
            <div class="mb-2">
                <div class="d-flex pb-2" v-if="relaisData.firmware !== 'Unknown'">
                    <div class="ms-2" :style="{width : '120px'}">Firmware</div>
                    <div class="ms-2">{{ relaisData.firmware }}</div>
                </div>
                <div class="d-flex pb-2" v-if="relaisData.serial_number !== 'Unknown'">
                    <div class="ms-2" :style="{width : '120px'}">Serial number</div>
                    <div class="ms-2">{{ relaisData.serial_number }}</div>
                </div>
                <ConnectRelais
                    :inputs="relaisData.in"
                    :outputs="relaisData.out"
                    :connected="relaisData.connected"
                    :errors="relaisData.errors"
                    :last_seen="relaisData.last_seen"
                    :metaData="nodeMeta"
                />
            </div>
        </v-card>
    </div>
</template>
<script>
import ConnectRelais from "@/components/NewModule/Relais/ConnectRelais.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "NewRelais",
    components: {ConnectRelais},
    props: {
        relaisData: {
            type: Object,
            default: () => {}
        },
        ctzId: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            dataLoaded: false
        };
    },
    methods: {
        ...mapActions({'getMeta': 'ctz/getData'}),
        getType(id){
            const types = {
                'czc': 'Cityzenz Connect',
                'czi': 'Cityzenz I',
                'czii': 'Cityzenz II'
            }
            return Object.keys(types).includes(this.relaisData.type) ? types[this.relaisData.type] : 'Unknown';
        }
    },
    computed: {
        ...mapGetters({'nodeMeta': 'ctz/StateNodesingle'}),
    },
    async mounted() {
        await this.getMeta(this.ctzId);
        this.dataLoaded = true;
    }
}
</script>
<style scoped>

</style>
