<template>
    <div>
        <v-card
            class="cardtitle"
            :height="cardHeight"
            width="490"
        >
            <v-card-title class="d-flex flex-column text-md-h5 cardtitle">
                <div :class="[cardPadding ? 'pt-3' : '', 'me-auto']">
                  <slot></slot>
                </div>
                <div class="me-auto">
                  <slot name="subtitle"></slot>
                </div>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "TitleCard",
    mounted() {
      console.log(this.$slots.subtitle);
    },
    data() {
        return {
          subtitle: this.$slots.subtitle,
        };
    },
    computed: {
        cardHeight() {
            if(this.subtitle && this.subtitle.length > 0) return 111;
            return 96;
        },
        cardPadding() {
          return !(this.subtitle && this.subtitle.length > 0);
        },
    },
}
</script>

<style scoped>
.cardtitle {
    color: #636363;
    font-weight: bold;
    background-color: #E3E3E3;
}
</style>
