<template>
    <div>
        <v-dialog
            :value="dialog"
            @input="$emit('input', event)"
            width="500px"
            z-index="9998"
            v-if="editedItem"
            persistent
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Edit Node Properties</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    color="red"
                                    label="Node id"
                                    :value="editedItem.id"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    color="red"
                                    label="Hardware id"
                                    :value="editedItem.hardware.hardware_id"
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    label="GPS"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col class="mx-auto" cols="3">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-if="gpsType === 'mdi-map-marker-question-outline' || gpsType === 'mdi-map-marker'">
                                            <router-link
                                                :to="{ name: 'Map', params: { centerOnCoords: editedItem.location }}"
                                            >
                                                <v-icon id="gpsIcon" :color="gpsColor" v-on="on" v-bind="attrs" large>{{ gpsType }}</v-icon>
                                            </router-link>
                                        </div>
                                        <div v-else>
                                            <v-icon id="gpsIcon" :color="gpsColor" v-on="on" v-bind="attrs">{{ gpsType }}</v-icon>
                                        </div>
                                    </template>
                                    <span v-if="gpsType === 'mdi-map-marker-question-outline'">The GPS is of type "fixed".</span>
                                    <span v-else-if="gpsType === 'mdi-map-marker'">The GPS is live and active.</span>
                                    <span v-else>No information for GPS found.</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Online"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-icon id="nodeOnline" :color="onlineColor" large>{{ nodeOnline }}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="6"
                            >
                                <v-text-field
                                    color="red"
                                    v-model="editedItem.user_field"
                                    label="Userfield"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-select
                                    color="accent"
                                    v-model="selectSched"
                                    :items="schedules.map((sched) => sched.name)"
                                    :label="$t('schedule.chooseSchedule')"
                                    density="compact"
                                    chips
                                    variant="solo"
                                    small-chips
                                    hide-selected
                                    deletable-chips
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="warning"
                        text
                        @click="emptyAllAndSetDialogToFalse"
                    >
                        {{ $t('main.cancel') }}
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="save"
                    >
                        {{ $t('main.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {cloneDeep as _cloneDeep} from 'lodash';
import {getScheduleIds, setUserfield} from "../services/apiHelpers";

export default {
    name: "NodeDialog",
    created() {
        this.init();
    },
    data() {
        return {
            editedItem: this.dialogItem,
            selectSched: [],
            schedules: [],
            hasChanged: false
        };
    },
    props: {
        dialogItem: {
            type: Object,
            default: () => {},
        },
        dialog: {
            type: Boolean,
            default: false
        }
    },
    computed:{
        ...mapGetters({ 'stateSchedules': 'sched/getSchedules', 'activeOrganisation': 'auth/StateActiveOrganisation' }),
        gpsType(){
            let gpstype = 'mdi-error';
            // test if an object is empty (object can have the property, but no members in it)
            if (this.editedItem.location && !this.objectIsEmpty(this.editedItem.location)){
                if (this.editedItem.location.type === 'fixed'){
                    gpstype = 'mdi-map-marker-question-outline';
                } else {
                    gpstype = 'mdi-map-marker';
                }
            } else {
                gpstype = 'mdi-minus';
            }
            return gpstype;
        },
        gpsColor(){
            let gpscolor = 'red';
            if (this.editedItem.location && !this.objectIsEmpty(this.editedItem.location)){
                if (this.editedItem.location.type === 'fixed'){
                    gpscolor = 'orange';
                } else {
                    gpscolor = 'green';
                }
            }
            return gpscolor;
        },
        nodeOnline(){
            let online = 'mdi-cancel';
            if (this.editedItem.connection.online){
                online = 'mdi-check-circle';
            }
            return online;
        },
        onlineColor(){
            let onlinecolor = 'red';
            if (this.editedItem.connection.online){
                onlinecolor = 'green';
            }
            return onlinecolor;
        }
    },
    methods: {
        ...mapActions({'sendScheduleToNodes': 'sched/sendScheduleToNodes', 'getSchedules': 'sched/Schedules'}),
        objectIsEmpty(obj){
            return Object.entries(obj).length === 0;
        },
        init() {
            getScheduleIds(this.activeOrganisation, 1)
                .then((res) => {
                    this.schedules = _cloneDeep(res);
                })
            if(this.editedItem.schedule){
                this.selectSched = this.editedItem.schedule.name;
            }
            // this.selectSched = this.schedules.map((el) => el.name);
            this.editedItem = _cloneDeep(this.dialogItem);
            this.dialog = this.toggle;
        },
        cancel() {
            this.emptyAllAndSetDialogToFalse();
        },
        save() {
            // check if anything has changed:
            // userfield or schedule
            // send data to API
            if (this.editedItem.user_field !== this.dialogItem.user_field){
                this.hasChanged = true;
                // send userfield to API
                this.sendUserfieldToNode();
            }

            if (this.selectSched !== this.dialogItem.schedule.name){
                this.hasChanged = true;
                // send schedule to API
                this.sendScheduleToNode();
            }

            this.emptyAllAndSetDialogToFalse();
        },
        emptyAllAndSetDialogToFalse(){
            this.editedItem = null;
            this.selectSched = [];
            this.schedules = null;
            this.$emit('has-changed', {changed: this.hasChanged, close: true});
        },
        sendScheduleToNode(){
            /*
            * nodesList = {
                   listOfNodes: nodes (1 or more in a string),
                   schedule_uid: uid of the schedule
                   test: true/false
}
            */
            const schedule_uid = this.schedules.find((el) => el.name === this.selectSched).uid;
            this.sendScheduleToNodes({listOfNodes: `${this.editedItem.id}`,
                schedule_uid: schedule_uid, test: false});
        },
        async sendUserfieldToNode() {
            await setUserfield(this.editedItem.id, this.editedItem.user_field);
        }
    },
};
</script>
