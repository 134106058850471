<template>
    <div>
        <v-dialog
            v-model="dialog"
            width="500px"
            z-index="9999"
            persistent
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="dialogType === 'add'"
                    color="blue"
                    dark
                    class="mx-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    {{ $t('main.addZone') }}
                </v-btn>
                <v-icon
                    v-else-if="dialogType === 'edit'"
                    small
                    class="mr-2"
                    @click="editItem"
                    v-bind="attrs"
                    v-on="on"
                    color="blue"
                >
                    mdi-pencil
                </v-icon>
            </template>


            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t(formTitle) }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="6"
                            >
                                <v-text-field
                                    color="red"
                                    v-model="editedItem.name"
                                    :label="$t('main.name')"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-text-field
                                    color="red"
                                    v-model="editedItem.city"
                                    :label="$t('main.city')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="warning"
                        text
                        @click="close"
                    >
                        {{ $t('main.cancel') }}
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="save"
                        :disabled="!isValid"
                    >
                        {{ $t('main.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import {addZone, editZone} from "@/services/apiHelpers";
import {cloneDeep as _cloneDeep} from 'lodash';
import {mapGetters} from "vuex";

export default {
    name: "ZoneDialog",
    created(){
        this.init();
    },
    props: {
        dialogType: {
            type: String, // 'add' for ADD-BUTTON, 'edit' for EDIT PENCIL, 'nothing' for NO TEMPLATE
            required: false,
            default: '',
        },
        dialogItem: {
            type: Object,
            required: false,
            default: null,
        },
        toggle: {
            type: Boolean,
            required: false,
            default: false
        },
        dialogPoly: {
            type: Array,
            required: false,
            default: null
        }
    },
    emits: ['data-changed'],
    data() {
        return {
            dialog: false,
            dialogDelete: false,
            editedItem: {
                name: '',
                city: '',
                latlngs: '',
                nodes: [],
            },
            defaultItem: {
                name: '',
                city: '',
                latlngs: '',
                nodes: [],
            },
            dataChanged: false,
        };
    },
    computed: {
        ...mapGetters({'selectedOrganisation': 'auth/StateActiveOrganisation'}),
        formTitle(){
            let title;
            switch(this.dialogType) {
                case 'add':
                    title = "main.newZone";
                    break;
                case 'edit':
                    title = "main.editZone";
                    break;
                case 'nothing':
                    title = "main.addZone";
                    break;
            }
            return title;
        },
        isValid(){
            return this.editedItem.name.length > 2 && this.editedItem.city.length > 2;
        }
    },
    methods: {
        init(){
            if(this.dialogType === 'add'){
                // add a new item
            } else if (this.dialogType === 'edit'){
                this.editedItem = _cloneDeep(this.dialogItem);
            } else if (this.dialogType === 'nothing') {
                this.dialog = this.toggle;
                this.editedItem.latlngs = this.dialogPoly;
            }
        },
        close(){
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
            })
        },
        async save() {
            let zone;
            if (this.dialogType === 'edit') {
                zone = editZone(this.selectedOrganisation, this.dialogItem.id, this.editedItem);
                this.dataChanged = true;
            } else if (this.dialogType === 'add' || this.dialogType === 'nothing') {
                zone = addZone(this.selectedOrganisation, this.editedItem);
                this.dataChanged = true;
            }
            Promise.all([zone])
                .then(() => {
                    this.$emit('data-changed', {isChanged: this.dataChanged, newZoneName: this.editedItem.name, newZoneCity: this.editedItem.city});
                    this.close();
                })


        },
        editItem(){
            this.dialog = true;
        },
    },
    watch: {
        dialog(val){
            val || this.close();
        },
        dialogDelete(val){
            val || this.closeDelete();
        },
    }
}
</script>

<style scoped>

</style>
