<template>
    <v-card height="30" class="mx-0 my-2 px-2 d-flex flex-row">
        <div class="px-4 my-auto justify-start" :style="{width: '12em', 'max-width': '12em'}">THE ACTION BAR</div>
        <div class="justify-start">
            <div class="mx-4 my-auto" id="no-chosen-filters-text" v-if="noChosenFilters"><strong>Choose a filter first..</strong></div>
            <div class="d-flex ms-4" v-else>
                <div v-for="filter in filterActions" :key="filter.id">
                    <v-btn class="action-button mx-2" small :disabled="!filter.active">{{ filter.title }}</v-btn>
                </div>
            </div>
        </div>
<!--        <div v-if="filter">-->
<!--            <div>information ..</div>-->
<!--        </div>-->
    </v-card>

</template>
<script>
export default {
    name: "ActionBar",
    props: {
        filterActions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        };
    },
    computed: {
        noChosenFilters() {
            return this.filterActions.length === 0;
        }
    },
    methods: {}
}
</script>
<style scoped>
#no-chosen-filters-text {
    color: red;
}
.action-button {
    background-color: gray;
    color: #1a1e21;
}
</style>
