<template>
    <div class="mt-2">
        <div v-if="hasInputs">
            <h4>INPUTS</h4>
            <div class="d-flex flex-column ">
                <div class="d-flex flex-row input justify-space-around my-6">
                    <!-- INPUTS -->
                    <div v-for="input in inputs" :key="input.caller" >
                        <div class="d-flex flex-row">
                            <div class="pa-2 my-auto">
                                <v-avatar
                                        :color="input.value === 0 ? 'green' : 'red'"
                                        rounded
                                        size="45"
                                >{{ input.name }}</v-avatar>
                            </div>
                            <div class="px-2 my-auto">
                                {{ input.value === 0 ? input.valueName[1] : input.valueName[0] }}
                            </div>
                        </div>
                        <div>
                            <h6>{{ readableDateTime(input.time) }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="hasOutputs">
            <h4>OUTPUTS</h4>
            <div class="d-flex flex-row output justify-space-around my-6">
                <!-- OUTPUTS -->
                <div v-for="output in outputs" :key="output.caller">
                    <div class="d-flex flex-row">
                        <div class="pa-2 my-auto">
                            <v-avatar
                                    :color="output.value === 0 ? 'grey' : 'yellow'"
                                    rounded
                                    size="45"
                            >{{ output.name }}</v-avatar>
                        </div>
                        <div class="px-2 my-auto">
                            {{ output.value === 0 ? output.valueName[0] : output.valueName[1] }}
                        </div>
                    </div>
                    <div>
                        <h6>mode: {{ output.mode ? output.mode : "schedule" }}</h6>
                    </div>
                    <div>
                        <h6>{{ readableDateTime(output.time) }}</h6>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="hasInputs || hasOutputs">
            <h4>MISC</h4>
            <div class="d-flex flex-column misc my-6">
                <div class="d-flex flex-row pb-2">
                    <div class="ms-4 label-text" :style="{width : '120px'}">
                        Errors
                    </div>
                    <div class="ms-2 value-text">
                        {{ errors }}
                    </div>
                </div>
                <div class="d-flex flex-row pb-2">
                    <div class="ms-4 label-text" :style="{width : '120px'}">
                        Connected
                    </div>
                    <div class="ms-2 value-text">
                        {{ connected }}
                    </div>
                </div>
                <div class="d-flex flex-row pb-2" v-if="last_seen">
                    <div class="ms-4 label-text" :style="{width : '120px'}">
                        Last seen
                    </div>
                    <div class="ms-2 value-text">
                        {{ readableDateTime(last_seen) }} ({{ formatTime() }})
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {dateTimeStrConv} from "@/services/dateConverter";
import dayjs from "dayjs";

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export default {
    name: "ConnectRelais",
    props: {
        inputs: {
            type: Array,
            default: () => []
        },
        outputs: {
            type: Array,
            default: () => []
        },
        errors: {
            type: Number,
            default: 0
        },
        connected: {
            type: Boolean,
            default: false
        },
        last_seen: {
            type: String,
            default: ""
        },
        metaData: {
            type: Object,
            default: () => {}
        }
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            modules: []
        };
    },
    methods: {
        init(){
            const modules = this.metaData.hardware.modules.map((module) => module.type).filter((type) => ['czc', 'czii', 'czi'].includes(type));
            this.modules = [...modules];
        },
        readableDateTime(dateTimeStr){
            if (dateTimeStr) return dateTimeStrConv(dateTimeStr, this.$i18n.locale);
            else return "";
        },
        formatTime() {
            const time = dayjs(this.last_seen);
            return time.fromNow();
        },

    },
    computed: {
        hasInputs() {
            return this.inputs.length > 0;
        },
        hasOutputs() {
            return this.outputs.length > 0;
        }
    },
}
</script>
<style scoped>
.input, .output, .misc {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px 0 10px 0;
}
.label-text {
    font-size: small;
    color: black;
}
.value-text {
    font-size: small;
    color: gray;
}

</style>
