<template>
    <div id="getzones" v-if="dataLoaded">
        <v-row justify="center">
            <v-col md="12">
                <v-card elevation="5" md="12">
                    <div>
                        <delete-dialog :toggleDialog="dialogDelete" itemType="zone" @confirm-dialog="checkConfirm" v-if="dialogDelete"/>
                        <v-data-table
                            :headers="headers"
                            :item-key="zones.id"
                            :items="zones"
                            :search="search"
                            class="elevation-5"
                            :footer-props="{
                                itemsPerPageText: $t('main.rowsPerPage'),
                                pageText: '{0} - {1} ' + $t('main.of') + ' {2}'
                            }"
                            :no-data-text="$t('main.noDataFound')"
                            :no-results-text="$t('main.noSearchResults')"
                            :options.sync="options"
                        >
                            <template v-slot:top>
                                <v-toolbar class="justify-space-between" flat>
                                    <v-toolbar-title class="mr-8"><strong>{{ $t('menu.buttons.zones') }}</strong></v-toolbar-title>
                                    <v-spacer />
                                    <v-text-field
                                        style="width: 100px"
                                        ref="zoneSearch"
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        class="mx-4 searchbar-width"
                                        clearable
                                        outlined
                                        dense
                                        hide-details
                                        :label="$t('main.search')"
                                        single-line
                                        solo
                                        color="accent"
                                    />
<!--                                    <zone-dialog dialog-type="add" @data-changed="checkChange"/> Disabled for now. No use for new zone without shape-coordinates? -->
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <div class="d-flex flex-row">
                                    <zone-dialog dialog-type="edit" :dialog-item="item" @data-changed="checkChange"/>
                                    <v-icon
                                        small
                                        @click="deleteItem(item)"
                                        color="red"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </div>
                            </template>
                            <template v-slot:item.shape="{ item }">
                                <div style="cursor: pointer" @click="gotoMap(item)">
                                    <v-icon>
                                        mdi-map-marker
                                    </v-icon>
                                </div>
                            </template>
                            <template v-slot:item.nodes="{ item }">
                                <div v-if="item.nodes && item.nodes.length > 0">
                                    <div style="cursor: pointer" v-for="node in item.nodes" :key="node">
                                        <router-link class="nodeLink" :to="{name: 'Dashboard', params: { ctzid: node }}">{{ node }}</router-link>
                                    </div>
                                </div>
                                <div v-else>-</div>
                            </template>
                            <template v-slot:item.schedules="{ item }">
                                <div class="d-flex flex-row align-center" style="width: 260px; height: 60px;" v-if="item.nodes.length > 0">
                                    <v-select
                                        color="accent"
                                        v-model="selectSched[item.id]"
                                        :items="schedules.map((el) => el.name)"
                                        :label="$t('schedule.chooseSchedule')"
                                        density="compact"
                                        chips
                                        variant="solo"
                                        small-chips
                                        hide-selected
                                        deletable-chips
                                    ></v-select>
                                    <v-btn class="ml-4" small @click="sendScheduleToNodes(item)" :disabled="!selectSched[item.id]">
                                        {{ $t('main.assign') }}</v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters} from "vuex";
import {getZones, deleteZone, getScheduleIds, assignScheduleToZone} from '@/services/apiHelpers';
import ZoneDialog from "@/components/ZoneDialog.vue";
import DeleteDialog from "@/components/Schedule/DeleteDialog.vue";

export default {
    name: "Zones",
    components: {
        DeleteDialog,
        ZoneDialog
    },
    async mounted() {
        await this.populateList();
    },
    data() {
        return {
            search: '',
            dataLoaded: false,
            zones: [],
            selectedOrganisation: '',
            dataChanged: false,
            dialogDelete: false,
            dialog: false,
            schedules: [],
            selectSched: [],
            deletedItem: null,
            options: {
                itemsPerPage: 10,
                page: 1
            },
        };
    },
    computed: {
        ...mapGetters({
            'authStatus': 'auth/authStatus',
            'activeOrganisation': 'auth/StateActiveOrganisation',
        }),
        headers() {
            return [
                {id: 1, text: this.$t('main.name'), value: 'name'},
                {id: 2, text: this.$t('main.city'), value: 'city'},
                {id: 3, text: this.$t('main.viewOnMap'), value: 'shape' },
                {id: 4, text: this.$t('menu.buttons.nodes'), value: 'nodes'},
                {id: 5, text: this.$t('main.actions'), value: 'actions'},
                {id: 6, text: this.$t('schedule.assignToSchedule'), value: 'schedules', width: '350px'}
            ];
        },
        activeOrg() {
            return this.activeOrganisation;
        },
    },
    methods: {
        async populateList() {
            this.selectedOrganisation = this.activeOrganisation;

            if (!this.selectedOrganisation) {
                this.dataLoaded = true;
            }

            if (this.$route.query.page && this.$route.query.page !== 1) {
                this.options.page = parseInt(this.$route.query.page);
            }
            if (this.selectedOrganisation){
                try {
                  const zoneList = await getZones(this.selectedOrganisation)
                  if (zoneList) this.zones = [...zoneList['zones']];
                  this.getZoneDetails()
                      .then((schedules) => getScheduleIds(this.selectedOrganisation, 3))
                      .then((resp) => this.schedules = resp)
                      .catch((err) => console.log("error in getZonesDetails : ", err))
                      .finally(() => this.dataLoaded = true)
                } catch(error) {
                  console.log("There was an error in populateList : ", error)
                }
            }


        },
        getZoneDetails(){
            return Promise.all(this.zones.map(async zone => getZones(this.selectedOrganisation, zone.id)))
                .then((response) => response.map((zone, i) => {
                    this.$set(this.zones, i , zone);
                }))
                .catch((error) => console.log("There was an error : " + error));
        },
        deleteItem(item){
            this.deletedItem = item;
            this.dialogDelete = true;
        },
        closeDelete(){
            this.dialogDelete = false;
            this.dataChanged = true;
            this.deletedItem = null;
        },
        checkConfirm(data){
            if(data){
                this.deleteItemConfirm();
            } else {
                this.closeDelete();
            }
        },
        async deleteItemConfirm() {
            this.dialogDelete = true;
            await deleteZone(this.selectedOrganisation, this.deletedItem.id);
            this.closeDelete();
        },
        checkChange(changedObj) {
            this.dataChanged = changedObj.isChanged;
        },
        gotoMap(item){
            this.$router.push({name: 'Map', query: { name: item.name, city: item.city, id: item.id }});
        },
        sendScheduleToNodes(item) {
            const schedule = this.schedules.find((sched) => sched.name === this.selectSched[item.id]);
            assignScheduleToZone(this.selectedOrganisation, item.id, schedule.uid);
            this.selectSched = [];
        },
    },
    watch: {
        dataChanged(newVal, val){
            if(val !== newVal && newVal) {
                this.dataLoaded = false;
                this.dataChanged = false;
                this.populateList();
            }
        },
        options(newV, oldV){
            if(newV.page !== oldV.page && newV.page) {
                if (newV.page === 1) {
                    this.$router.push({ name: 'Zones'});
                } else this.$router.push({ name: 'Zones', query: { page: `${newV.page}` }});
            }
        }
    },
}
</script>
<style scoped>
.searchbar-width {
    max-width: 240px;
}
.nodeLink {
    color: #515151;
    text-decoration: none;
}
</style>
