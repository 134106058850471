<template>
  <v-row class="d-flex flex-row mb-4" >
        <div class="d-inline-flex mx-16 column-one">
            <div class="flex-column">
                <TitleCard class="py-2" v-if="info.user_field">
                    <h2>{{ info.user_field }}</h2>
                </TitleCard>
                <TitleCard class="py-2">
                    <div>{{ module }}</div>
                    <template v-slot:subtitle>
                      <h6>Hardware ID: {{ info.hardware_id }}</h6>
                    </template>
                </TitleCard>
                <!-- check for cityzenz type. only show when ctzii -->
                <div v-if="isDataLoaded">
                    <div v-if="energymeterData.length > 0" class="d-flex flex-column mt-2">
                        <div v-for="(item, index) in energymeterData" :key="index" class="energy-meter-box">
                            <div>
                                <EnergyMeterInfo :energyMeterInfo="energyMeterInfo(item['port_id'])" />
                            </div>
                            <div v-for="item2 in item.fases" :key="item2.id" class="d-inline-flex">
                                <Fase2 :is-title="item2.id === 0" :alert="item2.id > 0 && item2.voltage === 0" :show-cos-phi="energymeterData.showCosPhi">
                                    <template v-slot:Ftitle>{{ item2.id === 0 ? $t(item2.title) : item2.title }}</template>
                                    <template v-slot:Fvoltage>{{ item2.id === 0 ? $t(item2.voltage) : item2.voltage }}</template>
                                    <template v-slot:Fcurrent>{{ item2.id === 0 ? $t(item2.current) : item2.current }}</template>
                                    <template v-slot:Fpower>{{ item2.id === 0 ? $t(item2.power) : item2.power }}</template>
                                    <template v-slot:Fcosphi>{{ item2.cosphi }}</template>
                                </Fase2>
                            </div>
                            <div v-if="item.energyConsumed">
                                <smart-meters class="mt-6">
                                    <template #consumedmeter1>{{ item.energyConsumed }}</template>
                                </smart-meters>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-inline-flex mx-16 column-two">
            <v-col class="flex-column justify-center ml-9">
                <v-row>
                    <v-card width="200" height="64" class="my-4">
                        <div class="text-h3 my-2">CityZenz</div>
                    </v-card>
                </v-row>
                <v-row>
                    <Io2 :auto="io.automatic" :cablefault="io.cablefault" :emergency="io.emergency"
                         :online="io.online" :faseErr="io.faseError" :show="io.show"/>
                </v-row>
                <!-- check with v-if="isOnline" -->
                <v-row class="mt-6" v-if="isOnline">
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn color="warning" width="200" v-on="on" v-bind="attrs" @click="toggleOverride">{{ $t('menu.buttons.manualOverride') }}</v-btn>
                        </template>
                        <span>{{ $t('tooltip.manualOverride') }}</span>
                    </v-tooltip>
                    <div class="d-flex flex-column" v-if="showOverrides">
                        <div><v-btn width="200" @click="override('ON')" x-small>{{ $t('module.allIosOn') }}</v-btn></div>
                        <div><v-btn width="200" @click="override('OFF')" x-small>{{ $t('module.allIosOff') }}</v-btn></div>
                        <div><v-btn width="200" @click="disableOverride" x-small>disable override</v-btn></div>
                    </div>
                </v-row>
            </v-col>
        </div>
        <div class="d-inline-flex mx-16 column-three" >
            <div class="flex-column" style="box-shadow: #D3D3D3 3px 4px 5px 1px" v-if="isOnline">
                <v-card-title class="cardbackground">Relais</v-card-title>
                <div class="flex-row" v-if="relais.length > 0">
                    <NewRelais :ctz-id="selectedNode.id" :relais-data="relai"  v-for="relai in relais" :key="relai.id" />
                </div>
                <div v-else class="flex-row">
                    <h3>NO INPUTS/OUTPUTS FOUND</h3>
                </div>
            </div>
        </div>
    </v-row>
</template>

<script>
import Fase2 from '@/components/NewModule/Fase2.vue';
import Io2 from "@/components/NewModule/Io2.vue";
import TitleCard from "@/components/NewModule/TitleCard";
import {mapActions, mapGetters} from "vuex";
import SmartMeters from "@/components/NewModule/SmartMeters";
import { cloneDeep as _cloneDeep } from "lodash";
import Vue from "vue";
import axios_api from '@/services/http-common.js';
import NewRelais from "@/components/NewModule/Relais/NewRelais.vue";
import EnergyMeterInfo from "@/components/NewModule/EnergyMeterInfo.vue";

export default {
    name: "Module2",
    components: {
        EnergyMeterInfo,
        NewRelais,
        TitleCard,
        Fase2,
        Io2,
        SmartMeters,
    },
    created() {
        this.loggedin = this.isAuthenticated;
        this.getData();

        // refreshes every minute to make sure the data is recent
        // TODO : this was set to 10 seconds for BEURS. please set back to 60 seconds.
        this.interval = setInterval(() => this.getData(), 10000);
        this.$on('hook:beforeDestroy', () => clearInterval(this.interval));
    },
    data() {
        return {
            dataLoaded: false,
            loggedin: null,
            current_url: null,
            isCtznzii: null,
            showOverrides: false,
            ioOverride: null,
            energymeters: [],
            energymeterData: [],
            io: {
                online: false,
                automatic: false,
                cablefault: false,
                emergency: false,
                faseError: false,
                showFaseErr: false,
            },
            relais: [],
            info: {
                hardware_id: '',
                firmware: 'None',
                connected: 0,
                connections: 0,
                type: 'None',
                port: 0,
                mode: 0,
                user_field: ''
            },
            module: 'Test Module Name',
            module_info: null,
            module_info_organ: '',
            module_info_proj: '',
            nrOfProjects: 0,
            nrOfSections: 0,
            module_info_sections: '',
            selectedNode: null,
            showSmartMeters: false,
            showFases: false,
            faseErrorInfo: '',
            CTZ: null,
        }
    },
    props: {
        ctzid: {
            type: [Number, String],
            default: 0,
            required: true
        }
    },
    computed: {
        ...mapGetters({'isAuthenticated': 'auth/isAuthenticated', 'StateNodeLast': 'ctz/StateNodeLast',
            'StateNodeSingle': 'ctz/StateNodesingle'}),
        isOnline() {
            return this.selectedNode ? this.selectedNode.connection.online : false;
        },
        isDataLoaded() {
            return this.dataLoaded;
        }
    },
    methods: {
        ...mapActions({'getNodeData': 'ctz/getData', 'getNodeLastData': 'ctz/getLastData'}),
        randomIntFromInterval(min, max) {
            // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min)
        },
        nrOfDecimals(value, decimals){
           return parseFloat(value.toFixed(decimals));
        },
        async getData(){
            try {
                await this.getNodeData(this.ctzid);
                await this.getNodeLastData(this.ctzid);
                this.isCtznzii = this.StateNodeSingle['hardware']['modules'].findIndex((module) => module['type'] === 'czii') !== -1;
                this.selectedNode = Object.assign({}, _cloneDeep(this.StateNodeLast), _cloneDeep(this.StateNodeSingle));

                const energyMeterPorts = this.selectedNode.hardware.modules.map((module) => {
                  if (module.type === 'energy_meter' || module.type === 'p1_meter') return module.port_id
                }).filter((x) => x);
                const enegymetersLength = this.energymeters.length;
                if (energyMeterPorts.length) this.energymeters.splice(0, enegymetersLength, ...energyMeterPorts);

                this.update_fases();
                this.update_relais();
                this.update_info();
            } catch(error) {
                console.log('There was an error while loading DATA during getData() in Module2.vue : ' + error);
            } finally {
                this.dataLoaded = true;
            }
        },
        update_fases(){
            // get data from the fases

            if (this.energymeters) {
                this.energymeters.forEach((meter) => {
                    const sensor = this.selectedNode.sensors.find((sensor) => sensor.port_id === meter);

                    // energy Consumed in kWh fixed to 2 decimals
                    let consumed = 0;
                    if (sensor.measurements["Energy.Consumed.Wh"]) consumed = this.nrOfDecimals((sensor.measurements["Energy.Consumed.Wh"].value / 1000),2);

                    const fases = [
                        {
                            id: 0,
                            title: 'module.infoUpper',
                            voltage: 'module.voltage',
                            current: 'module.current',
                            power: 'module.power',
                            cosphi: 'CosPhi',
                            showCosPhi: 'put false if all cosPhi values are 0.0',
                            active: 'put true .. if there is a voltage detected.'
                        },
                        {
                            id: 1,
                            title: 'L1',
                            voltageName: 'V1.mV',
                            voltage: 0.0,
                            currentName: 'I1.mA',
                            current: 0.0,
                            powerName: 'P.L1.W',
                            power: 0.0,
                            cosphi: 0.0,
                            showCosPhi: false,
                            active: false,
                        },
                        {
                            id: 2,
                            title: 'L2',
                            voltageName: 'V2.mV',
                            voltage: 0.0,
                            currentName: 'I2.mA',
                            current: 0.0,
                            powerName: 'P.L2.W',
                            power: 0.0,
                            cosphi: 0.0,
                            showCosPhi: false,
                            active: false,
                        },
                        {
                            id: 3,
                            title: 'L3',
                            voltageName: 'V3.mV',
                            voltage: 0.0,
                            currentName: 'I3.mA',
                            current: 0.0,
                            powerName: 'P.L3.W',
                            power: 0.0,
                            cosphi: 0.0,
                            showCosPhi: false,
                            active: false,
                        }
                    ]

                    if(Object.keys(sensor).length > 0){
                        for (let i = 1; i <= 3; i++) {
                            let phase = fases.find((elem) => elem.id === i);

                            let meas = this.selectedNode.sensors.find((sensor) => sensor.port_id === meter);

                            if (Object.keys(meas).length > 0) meas = meas.measurements;
                            else continue;

                            if (meas[fases[i].currentName]) {
                                // Current in A with 1 decimal
                                phase.current = this.nrOfDecimals((meas[fases[i].currentName].value / 1000),1);
                                phase.active = true;
                                this.showFases = true;
                            }
                            if (meas[fases[i].voltageName]) {
                                // Voltage in V with 0 decimals
                                phase.voltage = Math.round(meas[fases[i].voltageName].value / 1000);
                                phase.active = true;
                                this.showFases = true;
                            }
                            if (meas[fases[i].powerName]) {
                                // Power in kW fixed to 1 decimal
                                phase.power = this.nrOfDecimals((meas[fases[i].powerName].value / 1000), 1);
                                phase.active = true;
                                this.showFases = true;
                            }
                            // cosPhi fixed to 2 decimals (is between 0 and 1), don't show if unknown
                            phase.showCosPhi = phase.cosphi > 0.0;
                        }
                    }

                    let meterData = {
                        port_id: meter,
                        fases: fases,
                        energyConsumed: consumed,
                        faseErrorInfo: "",
                        showFaseError: false,
                        faseError: false,
                        showCosPhi: fases[1].showCosPhi || fases[2].showCosPhi || fases[3].showCosPhi
                    }
                    if (!meterData.fases[1].active || !meterData.fases[2].active || !meterData.fases[3].active) {
                        meterData.faseErrorInfo = "There is an error on one of the Fases!";
                        meterData.showFaseError = true;
                        meterData.faseError = true;
                    }

                    const index = this.energymeterData.findIndex((data) => data.port_id === meter);
                    const oldLength = this.energymeterData.length;
                    const start = index === -1 ? oldLength : index;
                    const deleteCount = index === -1 ? 0 : 1;
                    this.energymeterData.splice(start, deleteCount , meterData);
                })
            }
        },
        update_relais(){
            // Check for czii (with czi's) or czc (has no czi's YET)

            // each relai has an array of ins and outs
            // each in and out has:
            //  id: Number,
            //  path: String,
            //  value: Number,
            //  name: String
            //  valueName: {
            //      0: String,
            //      1: String
            //  }
            const modules = this.selectedNode.hardware.modules.filter((module) => ['czii', 'czi', 'czc'].includes(module.type));

            // Loop through the modules (cz2 has multiple cz1's and czc has only czc)
            for (let module of modules){
                // Regexps for mathing the ios
                const reInCzc = new RegExp("^io\\.([12])$", "g");
                const reOutCzc = new RegExp("^io\\.([34])$", "g");
                const reInCz2 = new RegExp(`^io\\.${module.port_id}\\.\\d`, "g");
                const reOutCz2 = new RegExp(`^io\\.${module.port_id}\\.\\d`, "g");


                const outputs = this.StateNodeSingle['ios'].outputs;
                const inputs = this.StateNodeSingle['ios'].inputs;
                const iosLast = this.StateNodeLast['ios'];
                const status = this.StateNodeLast.status;
                const connection = this.StateNodeLast.connection;

                const mod = {
                    type: module.type,
                    active: connection.online,
                    // be careful with the port_id. czc has no port_id yet. And port_id can be 0. Which is a falsy value.
                    port_id: module.port_id !== undefined ? module.port_id : -1,
                    serial_number: module.serial_number ? module.serial_number : 'Unknown',
                    firmware: module.firmware ? module.firmware : 'Unknown',
                    connected: status[`connected${module.port_id !== undefined ? '.' + module.port_id : ''}`],
                    errors: status[`errors${module.port_id !== undefined ? "." + module.port_id : ''}`],
                    last_seen: connection.last_seen,
                    out: Object
                        .keys(outputs)
                        .filter((output) => {
                            if (module.type === 'czc'){
                                return outputs[output].path.match(reOutCzc);
                            } else {
                                return  outputs[output].path.match(reOutCz2);
                            }
                        })
                        //.startsWith(`io${module.port_id !== undefined ? '.' + module.port_id : ''}`))
                            // connect has path IN: io.1, io.2 OUT: io.3 io.4
                            // cz2 and cz1 has path IN: io.0.1 OUT: io.1.1 etc. where the middle 1 is the port_id
                            // Connect does not have other INPUTS as far as we know of.
                        .map((call) => {
                            return {
                                caller: call,
                                name: outputs[call].name,
                                value: iosLast.outputs[call].value,
                                time: iosLast.outputs[call].time,
                                valueName: {
                                    0: "OFF",
                                    1: "ON"
                                },
                                path: outputs[call].path,
                                mode: status[`mode.${call}`]
                            }
                        }),
                    in: Object
                        .keys(inputs)
                        .filter((input) => {
                            if (module.type === 'czc'){
                                return inputs[input].path.match(reInCzc)
                            } else {
                                return  inputs[input].path.match(reInCz2);
                            }
                        })
                        // .startsWith(`io${module.port_id !== undefined ? '.' + module.port_id : ''}`))
                        // see above
                        .map((call) => {
                            return {
                                caller: call,
                                name: inputs[call].name,
                                value: iosLast.inputs[call].value,
                                time: iosLast.inputs[call].time,
                                valueName: {
                                    0: "OPEN",
                                    1: "CLOSED"
                                },
                                path: inputs[call].path
                            }
                        }),
                }
                const index = this.relais.findIndex((relai) => relai.port_id === mod.port_id);
                if (index !== -1) this.relais.splice(index, 1, mod);
                else {
                    const oldLength = this.relais.length;
                    this.relais.splice(oldLength, 0, mod);
                }
                this.relais[0].firmware = '1.2.1';
                this.relais[0].serial_number = '123456789';
                this.relais[0].last_seen = '2024-07-10T10:47:36Z';
            }
        },
        update_info(){
            const types = {
                "czc": "Cityzenz Connect",
                "czii": "Cityzenz 2"
            }
            const type = this.selectedNode.hardware.modules
                .find((module) => ['czc', 'czii'].includes(module.type)).type || 'Unknown type';
            this.module = `${this.selectedNode.id} (${types[type]})`;

            this.info['hardware_id'] = this.StateNodeSingle['hardware'].hardware_id;
            this.info['user_field'] = this.StateNodeSingle.user_field;

            this.io['online'] = !!this.StateNodeLast.connection.online;

            // automatic is CTZNZmode and will be implemented in backend.
            this.io['automatic'] = true; // 0 = production, 1 = manual, 2 = test
            // cablefault is from status.errors (bitwise AND with 0x20)
            // this.io['cablefault'] = (this.selectedNode.status[`errors.${this.CTZ.czii.port_id}`] & 0x20);
            this.io['emergency'] = false;
        },
        toggleOverride(){
            this.showOverrides = !this.showOverrides;
            // get modes and ios ?
            if (this.showOverrides) {
                axios_api.get(`nodes/${this.selectedNode.id}/ios`)
                    .then((resp) => {
                        this.ioOverride = resp.data;
                        return resp.data;
                    })
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((err) => console.error("ERROR !!", err));
            }
        },
        override(type){
            const out = Object.keys(this.selectedNode.ios.outputs);
            let body = {
                "status": {
                    "expires": 15
                },
                "outputs": {},
                "reason": "frontend-manual-override"
            }
            if (type === 'ON'){
                // set all IOs to value 1
                for (let output of out) {
                    body["status"][`mode.${output}`] = "override";
                    body.outputs[output] = 1;
                }
                this.io.overrideMode = true;
            } else {
                // set all IOs to value 0
                for (let output of out) {
                    body["status"][`mode.${output}`] = "override";
                    body.outputs[output] = 0;
                }
            }
            axios_api.post(`nodes/${this.selectedNode.id}/mode_ios`, body)
                .then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        this.$store.dispatch('not/successMsg', `Override on node ${this.selectedNode.id} was succesfull` )
                    }
                })
                .catch((err) => {
                    this.$store.dispatch('not/errorMsg', `Override on node ${this.selectedNode.id} was NOT succesfull. See console for error message.`)
                    console.error(`Error while overriding node ${this.selectedNode.id} : `, err);
                })
        },
        disableOverride(){
            let body = {
                "status": {
                    "mode": "schedule"
                }
            };
            axios_api.post(`nodes/${this.selectedNode.id}/mode`, body).then(() => this.showOverrides = false)
            this.io.overrideMode = false;
        },
        energyMeterInfo(port_id){
            if (this.selectedNode) {
                return this.selectedNode['hardware'].modules.find((module) => module.port_id === port_id);
            } else return {};
        }
    }
}
</script>
<style>
.cardbackground {
    background-color: #E3E3E3;
}
.column-one {
    width: 500px;
}
.column-two {
    width: 300px;
}
.column-three {
    width: 500px;
}
.button-disabled {
    cursor: default;
}

.energy-meter-box {
    border: 1px solid black;
    border-radius: 2px;
    margin: 5px 0 5px 0;
}
</style>
