<template>
        <v-card
            class="pa-2 flex-column"
            :height="cardHeight"
            :width="cardWidth"
        >
            <v-card
                class="card d-flex my-2"
                color="#bebebe"
                height="50"
                :width="fieldWidth"
            >
                <v-card-text class="text-h5 text-center">
                    <slot name="Ftitle"></slot>
                </v-card-text>
            </v-card>
            <v-card
                class="card d-flex my-2 pa-4"
                :color="alertColor"
                dark
                height="50"
                :width="fieldWidth"
            >
                <v-card-text :style="cardTextStyle" class="ma-0 pa-0 text-center">
                    <slot name="Fvoltage"></slot>
                </v-card-text>
            </v-card>
            <v-card
                class="card d-flex my-2 pa-4"
                color="secondary"
                dark
                height="50"
                :width="fieldWidth"
            >
                <v-card-text :style="cardTextStyle" class="ma-0 pa-0 text-center">
                    <slot name="Fcurrent"></slot>
                </v-card-text>
            </v-card>
            <v-card
                class="card d-flex my-2 pa-4"
                color="secondary"
                dark
                height="50"
                :width="fieldWidth"
            >
                <v-card-text :style="cardTextStyle" class="ma-0 pa-0 text-center">
                    <slot name="Fpower"></slot>
                </v-card-text>
            </v-card>
            <v-card
                class="card d-flex my-2 pa-4"
                color="secondary"
                dark
                height="50"
                :width="fieldWidth"
                v-if="showCosPhi"
            >
                <v-card-text :style="cardTextStyle" class="ma-auto pa-0 text-center">
                    <slot name="Fcosphi"></slot>
                </v-card-text>
            </v-card>
        </v-card>
</template>

<script>
export default {
    name:'Fase2',
    props: {
        isTitle: {
            type: Boolean,
            default: false
        },
        alert: {
            type: Boolean,
            default: false
        },
        showCosPhi: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cardWidth: this.isTitle ? '160px' : '110px',
            fieldWidth: this.isTitle ? '150px' : '100px',
            cardHeight: this.showCosPhi ? '300px' : '250px',
            cardTextStyle: {
                'font-size': '14px',
                'font-weight': 'bold',
                'color': this.isTitle ? '#7e7e7e' : '#000',
                },
            }
    },
    computed: {
        alertColor () {
            if (this.alert) return "#EABABD";
            else return "secondary";
        }
    }
}
</script>
