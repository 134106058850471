<template>
    <!-- Filters -->
    <v-card
            :style="{width: '12em', height: '100%', 'max-width': '12em'}"
    >
        <v-toolbar
                dense
                color="red"
        >
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>FILTERS</v-toolbar-title>
        </v-toolbar>
        <v-list
                color="primary"
                dense
        >
            <v-list-group
                    v-for="item in filters"
                    :key="item.title"
                    v-model="item.active"
                    color="red"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title><h3>{{ item.title }}</h3></v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                        v-for="child in item.items"
                        :key="child.title"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            <div
                                id="list-item-title"
                                v-if="child.active"
                                @click="deactivateItem(child)"
                            >{{ child.title }}</div>
                            <div
                                id="list-item-title-deactivated"
                                v-else
                                @click="activateItem(child)"
                            >{{ child.title }}</div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-card>
</template>
<script>
export default {
    name: "FiltersBar",
    data() {
        return {
            filters:[
                {
                    items: [
                        {title: 'All exceptions', active: false, actions: [] },
                        {
                            title: 'Offline', active: false, actions: [
                                {id: 1, title: 'Show Report', active: false, info: ''},
                                {id: 2, title: 'Send Alert', active: false, info: 'Send an alert to the user'},
                                {id: 3, title: 'Send Email', active: false, info: 'Send an email to the user'}
                            ]
                        },
                        {title: 'Door open', active: false, actions: []},
                        {title: 'Cable fault', active: false, actions: []},
                        {title: 'Voltage error', active: false, actions: []},
                        {title: 'Override mode', active: false, actions: []}
                    ],
                    title: 'EXCEPTIONS'
                },
                {
                    items: [
                        { title: 'ALL/NO nodes', active: false , actions: []},
                        {
                            title: 'Zone', active: false, actions: [
                                {id: 1, title: 'Show Report', active: false, info: ''},
                                {id: 2, title: 'Send Alert', active: false, info: 'Send an alert to the user'}
                            ]
                        },
                        { title: 'ID', active: false, actions: []},
                        { title: 'User field', active: false, actions: []},
                        { title: 'Serie number', active: false, actions: []},
                        { title: 'GPS location', active: false, actions: []}
                    ],
                    title: 'FILTER'
                },
            ],
        };
    },
    computed: {
        activeExceptions() {
            return this.filters[0].items.filter(item => item.active).length;
        },
        activeFilters() {
            return this.filters[1].items.filter(item => item.active).length;
        }
    },
    methods: {
        activateItem(item){
            // TODO check if this is correct!!
            const filters = this.filters.findIndex((flt) => flt.items.map((it) => it.title === item.title).filter(x => x).length > 0);
            const activeItems = filters === 0 ? this.activeExceptions : this.activeFilters;
            if(activeItems === 0){
                // Only if there are no active items in the filter can we activate the item
                item.active = true;
                this.$emit('activate-filter', item.title, item.actions);
            }
        },
        deactivateItem(item){
            const filters = this.filters.findIndex((flt) => flt.items.map((it) => it.title === item.title).filter(x => x).length > 0);
            const activeItems = filters === 0 ? this.activeExceptions : this.activeFilters;
            if (activeItems === 1 && item.active === true){
                // Only if there is one active item in the filter can we deactivate the item
                item.active = false;
                this.$emit('deactivate-filter', item.title);
            }
        }
    }
}
</script>
<style scoped>

#list-item-title {
    cursor: pointer;
    color: black !important;
}

#list-item-title-deactivated {
    cursor: pointer;
    color: gray !important;
}
</style>
