<template>
    <v-card class="cardbackground">
    <v-card-title class="px-4 cardbackground" height="30px">{{ $t('module.io') }}</v-card-title>
    <v-card
        class="px-2 flex-column pt-2"
        :height="cardHeight"
        width="200"
        elevation="0" rounded="0"
    > <!-- height 265 or 310 with or without EMERGENCY -->
<!--        <v-card-->
<!--            elevation="0"-->
<!--            width="190"-->
<!--            height="40"-->
<!--        >-->
<!--            <div-->
<!--                v-if="isOnline"-->
<!--                :title="$t(doorInfo)"-->
<!--                :class="classObject(!door)"-->
<!--                class="my-2 pa-2 rounded-pill text-center">{{ door ? $t("module.doorOpen") : $t("module.doorClosed") }}</div>-->
<!--        </v-card>-->
        <v-card
            elevation="0"
            width="190"
            height="40"
        >
            <div
                :title="$t(onlineInfo)"
                :class="classObject(online)"
                class="my-2 pa-2 rounded-pill text-center">{{ online ? "ONLINE" : "OFFLINE" }}</div>
        </v-card>
        <v-card
            elevation="0"
            width="190"
            height="40"
        >
            <div
                v-if="isOnline"
                :title="$t(cableFaultInfo)"
                :class="classObject(!cablefault)"
                class="my-2 pa-2 rounded-pill text-center">{{ cablefault ? $t('module.cableFault') : $t('module.noCableFault') }}</div>
        </v-card>
        <v-card
            elevation="0"
            width="190"
            height="40"
        >
            <div
                v-if="isOnline"
                :title="$t(automaticInfo)"
                :class="classObject(auto)"
                class="my-2 pa-2 rounded-pill text-center">{{ $t('module.automatic') }}</div>
        </v-card>
        <v-card
            elevation="0"
            width="190"
            height="40"
            v-if="showFaseErr"
        >
            <div
                v-if="isOnline"
                :title="$t(supplyErrorInfo)"
                :class="classObject(!faseErr)"
                class="my-2 pa-2 rounded-pill text-center">{{ $t('module.faseErr') }}</div>
        </v-card>
        <v-card
            v-if="emergency"
            elevation="0"
            width="190"
            height="40"
        >
            <div :class="classObject(!emergency)"
                 class="my-2 pa-2 rounded-pill text-center text-bold">EMERGENCY</div>
        </v-card>
    </v-card>
    </v-card>
</template>

<script>
export default {
    name: "Io2",
    props: {
        // door: {
        //     Boolean,
        //     default: false // is closed
        // },
        cablefault: {
            Boolean,
            default: false
        },
        online: {
            Boolean, // has been online the last 5 minutes
            default: true
        },
        auto: {
            Boolean,  // auto/hand -> auto is true
            default: true
        },
        emergency: {
            Boolean,  // true if there is an emergency
            default: false
        },
        faseErr: {
            Boolean,  // true if there is a supply error. one or more fases is 0V or less then 200V
            default: true
        },
        showFaseErr: {
            Boolean,
            default: false,
        }
    },
    data() {
        return {
            // pillColorDoor: !!this.door,
            pillColorCablefault: !!this.cablefault,
            pillColorOnline: !!this.online,
            pillColorAutomatic: !!this.auto,
            pillColorEmergency: !!this.emergency,
            pillColorFaseErr: !!this.faseErr,
            doorInfo: 'module.doorInfo',
            onlineInfo: 'module.onlineInfo',
            cableFaultInfo: 'module.cableFaultInfo',
            automaticInfo: 'module.automaticInfo',
            supplyErrorInfo: 'module.supplyErrorInfo',
        }
    },
    methods: {
        classObject: function(pillColor) {
            return {
                redlight: !pillColor,
                greenlight: pillColor,
            }
        },
    },
    computed: {
        cardHeight() {
            if (this.emergency) return 265;
            else return 220;
        },
        isOnline() {
            return this.online;
        },
    }
};
</script>

<style scoped>
.greenlight {
    background-color: green;
    color: white;
}
.redlight {
    background-color: #BE2830;
    color: white;
}
</style>
