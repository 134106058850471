<template>
  <v-card class="d-flex flex-column">
      <div class="d-flex">
          <div class="d-flex half-width" v-if="this.energyMeterInfo.brand">
              <div class="pa-4 gray-bg label label" style="width: 50%;">Brand</div><div class="ma-4 text text">{{this.energyMeterInfo.brand}}</div>
          </div>
          <div  class="d-flex half-width" v-if="this.energyMeterInfo.brand_type">
              <div class="pa-4 gray-bg label label" style="width: 50%;">Brand Type</div><div class="ma-4 text">{{this.energyMeterInfo.brand_type}}</div>
          </div>
      </div>
      <div class="d-flex">
          <div  class="d-flex half-width" v-if="this.energyMeterInfo.type">
              <div class="pa-4 gray-bg label" style="width: 50%;">Type</div><div class="ma-4 text">{{this.energyMeterInfo.type}}</div>
          </div>
          <div  class="d-flex half-width" v-if="energyMeterInfo.firmware">
              <div class="pa-4 gray-bg label" style="width: 50%;">Firmware</div><div class="ma-4 text">{{this.energyMeterInfo.firmware}}</div>
          </div>
      </div>
      <div class="d-flex">
          <div  class="d-flex full-width" v-if="energyMeterInfo.serial_number">
              <div class="pa-4 gray-bg label" style="width: 50%;">Serial Number</div><div class="ma-4 text">{{this.energyMeterInfo.serial_number}}</div>
          </div>
      </div>
  </v-card>
</template>
<script>
export default {
  name: "EnergyMeterInfo",
  props: {
    energyMeterInfo: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
    };
  },
  methods: {

  }
}
</script>
<style scoped>
.gray-bg {
  background-color: lightgray;
  border-bottom: 1px solid white;
}

.label, .text {
    font-size: x-small;
}
.text {
    font-weight: bold;
}

.half-width {
    width: 50%;
}

.full-width {
    width: 100%;
}
</style>
